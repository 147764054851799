import axios from "axios";

export function getCurrentPosition() {
  return axios.get(`http://127.0.0.1:8000/getCoords`)
      .then((res) => {
        console.log("response gps: ", res);
        return res
      })
      .catch((err) => {
        console.log("error in response gps data: ", err);
        return err
      })
}

export function httpSuccessTest(httpCode) {
  const regex = /^2.{2}/i;
  return regex.test(httpCode);
}