import './App.scss';
import React from "react";
import VideoContainer from "./videocall/components/VideoContainer";

function App() {
  return (
    <div className="app">
      <VideoContainer/>
    </div>
  );
}

export default App;
