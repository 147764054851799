import React from "react";
//import Track from "./Track";

class Participant extends React.Component {
  constructor(props) {
    super(props);

    const existingPublications = Array.from(this.props.participant.tracks.values());
    const existingTracks = existingPublications.map(publication => publication.track);
    const nonNullTracks = existingTracks.filter(track => track !== null)

    this.state = {
      tracks: nonNullTracks,
      audiotrack: null,
      videotrack: null,
      datatrack: null,
      muted: false,
      hidden: false,
      currentvideodevice: -1,
      videodevices: 0,
    }

    this.videocontainer = React.createRef();

    this.forceRefresh = this.forceRefresh.bind(this);
    this.handleRemoteMsg = this.handleRemoteMsg.bind(this);
    this.handleTrackPublished = this.handleTrackPublished.bind(this);
    this.attachAllTracks = this.attachAllTracks.bind(this);
    this.attachTrack = this.attachTrack.bind(this);
  }

  componentDidMount() {
    if (!this.props.localParticipant) {
      this.props.participant.on('trackSubscribed', track => this.addTrack(track));
    }
    if(this.props.participant) {
      this.attachAllTracks();
      this.props.participant.on('trackSubscribed', this.forceRefresh);
      this.props.participant.on('trackUnsubscribed', this.forceRefresh);
      this.props.participant.on('trackPublished', this.handleTrackPublished);
    }
  }

  forceRefresh() {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    console.log('PARTICIPANT DID UPDATE');
    if( prevProps.participant !== this.props.participant && prevProps.participant != null ) {
      console.log('switched participant');
      this.videocontainer.current.innerHTML = "";
    }
    if( this.props.participant ) {
      this.attachAllTracks();

      if(prevProps.participant !== this.props.participant) {
        //if(prevProps.participant) prevProps.participant.off('trackSubscribed', this.forceUpdate);
        //if(prevProps.participant) prevProps.participant.off('trackUnsubscribed', this.forceUpdate);
        this.props.participant.on('trackSubscribed', () => { this.props.onDataTrackReady(); this.forceRefresh(); });
        this.props.participant.on('trackUnsubscribed', this.forceRefresh);

        //if(prevProps.participant) prevProps.participant.off('trackPublished', this.handleTrackPublished);
        this.props.participant.on('trackPublished', this.handleTrackPublished);

      }
    }
  }

  attachAllTracks() {
    let _newaudiotrack = null;
    let _newvideotrack = null;
    let _newdatatrack = null;
    this.props.participant.tracks.forEach( publication => {
      if( publication.kind === 'audio' && publication.track !== this.state.audiotrack ) {
        //console.log('attach all audio track');
        if( publication.track && this.attachTrack(publication.track) )
          _newaudiotrack = publication.track;
      }
      else if( publication.kind === 'video' && publication.track !== this.state.videotrack ) {
        //console.log('attach all video track')
        if( publication.track && this.attachTrack(publication.track) )
          _newvideotrack = publication.track;
      }
      else if( publication.kind === 'data' && publication.track !== this.state.datatrack ) {
        //console.log('attach all data track')
        if( publication.track && this.attachTrack(publication.track) )
          _newdatatrack = publication.track;
      }
    } );
    if( _newaudiotrack != null || _newvideotrack != null || _newdatatrack != null ) {
      let diffState = {};
      if( _newvideotrack != null ) diffState['videotrack'] = _newvideotrack;
      if( _newaudiotrack != null ) diffState['audiotrack'] = _newaudiotrack;
      if( _newdatatrack != null ) {
        diffState['datatrack'] = _newdatatrack;
        setTimeout( () => {
          this.props.onDataTrackReady(this.props.participant);
        }, 2000);
      }
      //console.log('UPDATE STATE ', diffState);
      this.setState( diffState );
    }
  }

  attachTrack(track) {
    if( track.kind === 'audio' && this.state.audiotrack !== track ) {
      if(this.state.audiotrack !== null) {
        //let _at = this.state.audiotrack;
        let el = this.state.audiotrack.detach( this.videocontainer.current.querySelector("audio") );//.remove();
        //console.log('detached from ', el);
        if( !el.remove ) el.forEach( el => { if(el.remove) el.remove(); });
        else el.remove();
      }
      this.videocontainer.current.appendChild(track.attach());
      //console.log('attached audio track',this.state.audiotrack, track);
      track.on('enabled', (t) => { if( t === this.state.audiotrack ) this.setState({ muted: false }); } );
      track.on('disabled', (t) => { if( t === this.state.audiotrack ) this.setState({ muted: true }); } );
      return true;
    }
    else if( track.kind === 'video' && this.state.videotrack !== track ) {
      if(this.state.videotrack != null) {
        //let _vt = this.state.videotrack;
        let el = this.state.audiotrack.detach( this.videocontainer.current.querySelector("video") );//.remove();
        //console.log('detached from ', el);
        if( !el.remove ) el.forEach( el => {if(el.remove) el.remove(); });
        else el.remove();
      }
      this.videocontainer.current.appendChild(track.attach());
      //console.log('attached video track', this.state.videotrack, track);
      track.on('enabled', (t) => { if( t === this.state.videotrack ) this.setState({ hidden: false }); } );
      track.on('disabled', (t) => { if( t === this.state.videotrack ) this.setState({ hidden: true }); } );
      return true;
    }
    else if( track.kind === 'data' && this.state.datatrack !== track ) {
      if(this.props.onRemoteMessage) {
        //console.log('attached data track', this.state.datatrack, track);
        track.on( 'message', data => {
          let remoteMsg = JSON.parse(data);
          this.handleRemoteMsg(remoteMsg);
        } );
        return true;
      }
    }
    return false;
  }

  addTrack(track) {
    this.setState({
      tracks: [...this.state.tracks, track]
    });
  }

  handleRemoteMsg(msg) {
    if( msg.type === 'listvideodevices' && msg.participant && this.props.participant.identity === msg.participant) {
      let stateDiff = {
        videodevices: msg.videodevices,
        currentvideodevice: msg.currentvideodevice,
      }
      this.setState(stateDiff);
    }
    this.props.onRemoteMessage( msg );
  }

  handleTrackPublished(publication) {
    console.log("sono in handleTrackPublished");
    if( publication.track && this.attachTrack(publication.track) ) {
      if( publication.kind === 'audio' )
        this.setState({ audiotrack: publication.track });
      else if( publication.kind === 'video' )
        this.setState({ videotrack: publication.track });
      else if( publication.kind === 'data' ) {
        if( this.props.onDataTrackReady ) {
          this.props.onDataTrackReady();
        }
        this.setState({ datatrack: publication.track });
      }
    }
  }

  render() {
    return (
        <div className="participant" id={this.props.participant.identity}>
          <div className="identity">{ this.props.participant.identity}</div>
          {/*
            this.state.tracks.map(track =>
                <Track key={track} filter={this.state.filter} track={track}/>)
          */}
          <div ref={this.videocontainer}> </div>
        </div>
    );
  }
}

export default Participant;