import React from "react";
import Participant from "./Participant";
import {createLocalAudioTrack, createLocalVideoTrack} from "twilio-video";
import {getCurrentPosition, httpSuccessTest} from "../utils";

class Room extends React.Component {
  constructor(props) {
    super(props);

    this.sendLocationTimer = null;

    this.state = {
      remoteParticipants: Array.from(this.props.room.participants.values())
    }

    this.leaveRoom = this.leaveRoom.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.sendCurrentLocation = this.sendCurrentLocation.bind(this);
    this.onDataTrackReady = this.onDataTrackReady.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
    this.remoteMessageReceived = this.remoteMessageReceived.bind(this);
  }

  componentDidMount() {
    // Add event listeners for future remote participants coming or going
    this.props.room.on('participantConnected', participant => this.addParticipant(participant));
    this.props.room.on('participantDisconnected', participant => this.removeParticipant(participant));

    console.log('START SENDING LOCATION...');
    setTimeout(() => {
      console.log('timeout');
      this.sendCurrentLocation();
    }, 2000);
    this.sendLocationTimer = setInterval(() => {
      console.log('interval');
      this.sendCurrentLocation();
    }, 30000);

    window.addEventListener("beforeunload", this.leaveRoom);
  }

  sendCurrentLocation() {
    console.log('SENDING LOCATION...');

    getCurrentPosition().then((res) => {
      if (res && httpSuccessTest(res.status) && res.data) {
        let lat = res.data.latitude;
        let lng = res.data.longitude;
        let msg = {
          type: 'geolocation',
          participant: this.props.room.localParticipant.identity,
          lat: lat,
          lng: lng
        };
        this.sendMessage(msg);
      }
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentAudioDevice !== this.props.currentAudioDevice) {
      console.log('Creating new audio track ' + this.props.currentAudioDevice, this.props.room.localParticipant.tracks);
      Array.from(this.props.room.localParticipant.audioTracks.values()).forEach(t => {
        if (t.track.stop) t.track.stop();
      });
      createLocalAudioTrack({deviceId: this.props.currentAudioDevice})
          .then((localAudioTrack) => {
            console.log('created new audio track');
            const tracks = Array.from(this.props.room.localParticipant.audioTracks.values()).map(t => t.track);
            console.log('unpublishing old tracks', tracks);
            this.props.room.localParticipant.unpublishTracks(tracks);
            console.log('publishing new audio track', localAudioTrack);
            this.props.room.localParticipant.publishTrack(localAudioTrack);
            if (this.state.muted) localAudioTrack.disable();
            //this.setState({localAudioTrack: localAudioTrack });
          });
    }

    if (prevProps.currentVideoDevice !== this.props.currentVideoDevice) {
      //Array.from(this.props.room.localParticipant.videoTracks.values()).forEach(t => { t.track.stop(); });
      console.log('stopping local tracks');
      Array.from(this.props.room.localParticipant.videoTracks.values()).forEach(t => { if(t.track.stop) t.track.stop(); });
      const tracks = Array.from(this.props.room.localParticipant.videoTracks.values()).map(t => t.track);
      console.log('unpublishing old tracks');
      this.props.room.localParticipant.unpublishTracks(tracks);
      await new Promise( (resolve, reject) => { setTimeout(resolve, 1000); });

      createLocalVideoTrack({ deviceId: this.props.currentVideoDevice, width: 3840 })
          .then( (localVideoTrack) => {
            console.log('created new video track');
            //const tracks = Array.from(this.props.room.localParticipant.videoTracks.values()).map(t => t.track);
            
            console.log('publishing new track');
            this.props.room.localParticipant.publishTrack(localVideoTrack);
            if (this.state.hidden) localVideoTrack.disable();
            //this.setState({localVideoTrack: localVideoTrack });
          })
					.catch( (err) => { console.log('CREATE VIDEO TRACK ERROR', err); } );
    }

    if (prevState.muted !== this.state.muted) {
      if (this.state.muted) {
        this.props.room.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable();
        });
      } else {
        this.props.room.localParticipant.audioTracks.forEach(publication => {
          publication.track.enable();
        });
      }
    }

    if (prevState.hidden !== this.state.hidden) {
      if (this.state.hidden) {
        this.props.room.localParticipant.videoTracks.forEach(publication => {
          publication.track.disable();
        });
      } else {
        this.props.room.localParticipant.videoTracks.forEach(publication => {
          publication.track.enable();
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.sendLocationTimer) {
      clearInterval(this.sendLocationTimer);
    }

    this.leaveRoom();
  }

  leaveRoom() {
    this.props.room.disconnect();
    this.props.returnToLobby();
  }

  addParticipant(participant) {
    console.log(`${participant.identity} has joined the room.`);
    setTimeout(() => {
      this.onDataTrackReady();
    }, 2000);

    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant]
    });
  }

  removeParticipant(participant) {
    console.log(`${participant.identity} has left the room`);

    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(p => p.identity !== participant.identity)
    });
  }

  sendMessage(msg) {
    console.log("sono in sendMessage")
    let _localDataTrack = null;
    Array.from(this.props.room.localParticipant.dataTracks.values()).forEach(publication => {
      _localDataTrack = publication.track;
    });

    if (_localDataTrack) {
      console.log('SENDING MSG:', msg);
      _localDataTrack.send(JSON.stringify(msg));
    }
  }

  onDataTrackReady(participant) {
    console.log("sono in onDataTrackReady");
    let _currDevice = null;
    if (this.props.videoDevices.length) {
      for (let i = 0; i < this.props.videoDevices.length; i++) {
        if (this.props.videoDevices[i].deviceId === this.props.currentVideoDevice) {
          _currDevice = i;
          break;
        }
      }
    }
    let msg = {
      type: 'listvideodevices',
      participant: this.props.room.localParticipant.identity,
      videodevices: this.props.videoDevices.length,
      currentvideodevice: _currDevice,
    }
    this.sendMessage(msg);

    this.sendCurrentLocation();
  }

  remoteMessageReceived(msg) {
    console.log('Remote message received', msg);

    if (msg.type === 'switchcam' && msg.to && msg.to === this.props.room.localParticipant.identity) {
      console.log('switching cam...');
      for (let i = 0; i < this.props.videoDevices.length; i++) {
        if (this.props.videoDevices[i].deviceId === this.props.currentVideoDevice) {
          this.props.ondeviceselected('videoinput', this.props.videoDevices[(i + 1) % this.props.videoDevices.length].deviceId);
          return;
        }
      }
    }
  }

  switchCamera(participant) {
    console.log('Requesting switch camera to ' + participant.identity);
    let msg = {
      to: participant.identity,
      type: 'switchcam',
    }
    this.sendMessage(msg);
  }

  render() {
    return (
        <div className="room">
          <div className="participants">
            <Participant key={this.props.room.localParticipant.identity} localParticipant="true"
                         participant={this.props.room.localParticipant}
                         onDataTrackReady={this.onDataTrackReady}
                         switchCamera={this.switchCamera}
            />
            {
              this.state.remoteParticipants.map(participant =>
                  <Participant key={participant.identity} participant={participant}
                               onDataTrackReady={this.onDataTrackReady}
                               onRemoteMessage={this.remoteMessageReceived}/>
              )
            }
          </div>
          <button id="leaveRoom" onClick={this.leaveRoom}>Leave Room</button>
        </div>
    );
  }

}

export default Room;