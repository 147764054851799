import React from "react";
import axios from "axios";
import Room from "./Room";

const ROOT_URL = process.env.REACT_APP_API_URL;
const {connect, createLocalTracks, LocalDataTrack} = require('twilio-video');

class VideocallContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      room: null,
      audioDevices: [],
      videoDevices: [],
      currentAudioDevice: null,
      currentVideoDevice: null,
    }
    this.joinRoom = this.joinRoom.bind(this);
    this.returnToLobby = this.returnToLobby.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
  }

  componentDidMount() {
    //navigator.mediaDevices.enumerateDevices().then(devices => {
    //  devices.forEach(device => {
    //    if (device.kind == "audioinput") {
    //      console.log(device)
    //    }
    //  })
    //})
    // http://localhost:3000/?username=&password=
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    let user = params.get("username");
    let pwd = params.get("password");
    let roomName = params.get("room_name");
    navigator.mediaDevices.getUserMedia({audio: true, video: true})
        .then(stream => {
          let tracks = stream.getTracks();
          tracks.forEach(t => {
            t.stop();
          });

          var userAudioDevices = [];
          var userVideoDevices = [];
          navigator.mediaDevices.enumerateDevices()
              .then(devices => {
                console.log(devices);
                devices.forEach(device => {
                  if (device.deviceId !== 'default' && device.deviceId !== 'communications') {
                    if (device.kind === 'audioinput')
                      userAudioDevices.push(device);
                    else if (device.kind === 'videoinput')
                      userVideoDevices.push(device);
                  }
                });
                this.setState({
                  audioDevices: userAudioDevices,
                  videoDevices: userVideoDevices,
                  currentAudioDevice: userAudioDevices[0].deviceId,
                  currentVideoDevice: userVideoDevices[0].deviceId
                });
              });

          if (user && pwd && roomName) {
            this.joinRoom(user, pwd, roomName);
          }
        });
    
  }

  async joinRoom(user, pwd, roomName) {
    let token = null;
    let room = null;
    try {
      const responseLogin = await axios.post(`${ROOT_URL}/get-token/`, {
        username: user,
        password: pwd,
      })
      let tokenLogin = responseLogin.data.token;
      axios.interceptors.request.use(
          function (config) {
            //Set jwt token
            config.headers.Authorization = "JWT " + tokenLogin;
            return config;
          },
          function (error) {
            return Promise.reject(error);
          }
      );
      const response = await axios.get(`${ROOT_URL}/videoinspections/access/?room_name=${roomName}`);
      token = await response.data.token;
    } catch (err) {
      console.log(err);
    }
    if (token) {
      try {
        const dataTrack = new LocalDataTrack();
        const localTracks = await createLocalTracks({
          audio: true,
          video: { deviceId: this.state.currentVideoDevice, width: 3840 }
        });
        localTracks.push(dataTrack);
				console.log('LOCAL TRACKS', localTracks);
        room = await connect(token, {
          name: roomName,
          tracks: localTracks
        });
      } catch (err) {
        console.log(err);
        console.log("trying connecting only with video");
        try {
          room = await connect(token, {
            name: roomName,
            audio: false,
            video: { width: 1920 }
          });
        } catch (err) {
          console.log(err);
          console.log("trying connecting only with audio");
          try {
            room = await connect(token, {
              name: roomName,
              audio: true,
              video: { width: 1920 }
            });
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
    if (room) {
      this.setState({room: room});
    }
  }

  returnToLobby() {
    this.setState({room: null});
  }

  selectDevice(kind, deviceId) {
    if( kind === 'audioinput' )
      this.setState({ currentAudioDevice: deviceId });
    else if( kind === 'videoinput' )
      this.setState({ currentVideoDevice: deviceId });
  }

  render() {
    return (
        <div>
          {
            this.state.room === null
                ? <div className="lobby">
                  <p>
                    Credenziali non immesse
                  </p>
                </div>
                : <Room
                    returnToLobby={this.returnToLobby}
                    room={this.state.room}
                    audioDevices={this.state.audioDevices}
                    videoDevices={this.state.videoDevices}
                    ondeviceselected={this.selectDevice}
                    currentAudioDevice={this.state.currentAudioDevice}
                    currentVideoDevice={this.state.currentVideoDevice}
                />
          }
        </div>
    )
  }
}

export default VideocallContainer;